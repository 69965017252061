import axios from "axios";
import { HTTP_CODE_401, SEVERITY_ERROR } from "./constants";
import { turnOffLoadingActionCreator, turnOnLoadingActionCreator } from "../reducers/commonState";
import store from "../store/store";
import { showMessage } from "../components/common/notificationSnackComponent/NotificationSnackComponent";
import { language } from "./utils";

export const getOptions = (config, headers, withoutBearer = false) => {
  const keycloak = store.getState().common.keycloak;
  const options = {
    headers: {
      "Content-Type": "application/json",
    },
    ...config,
  };

  if (!!keycloak && !!keycloak.token && !withoutBearer) {
    options.headers.Authorization = `Bearer ${keycloak.token}`;
  }

  if (headers) {
    const keys = Object.keys(headers);

    for (const key of keys) {
      options.headers[key] = headers[key];
    }
  }

  return options;
};

const resolvePromise = (response, loading, responseThen) => {
  if (loading) {
    store.dispatch(turnOffLoadingActionCreator());
  }

  if (responseThen) {
    responseThen(response);
  }
};

const rejectPromise = (error, loading, responseCatch) => {
  const keycloak = store.getState().common.keycloak;

  if (loading) {
    store.dispatch(turnOffLoadingActionCreator());
  }

  if (error.response && error.response.status === HTTP_CODE_401 && !!keycloak && !keycloak.authenticated) {
    keycloak?.login();
  }

  if (responseCatch) {
    responseCatch(error, SEVERITY_ERROR);
  } else {
    if (error.response?.data?.message && error.response?.data?.statusCode) {
      const formattedError = {
        errorCode: error.response.data.statusCode,
        message: error.response.data.message,
      };
      showMessage(formattedError, SEVERITY_ERROR);
      return;
    }
    showMessage(error);
  }
};

export const put = (url, data, config, loading = true, responseThen, responseCatch, headers) => {
  if (loading) {
    store.dispatch(turnOnLoadingActionCreator());
  }
  axios
    .put(url, data, getOptions(config, headers))
    .then((response) => resolvePromise(response, loading, responseThen))
    .catch((error) => rejectPromise(error, loading, responseCatch));
};

export const post = (url, data, config, loading, responseThen = undefined, responseCatch = undefined, headers) => {
  if (loading) {
    store.dispatch(turnOnLoadingActionCreator());
  }
  axios
    .post(url, data, getOptions(config, headers))
    .then((response) => resolvePromise(response, loading, responseThen))
    .catch((error) => rejectPromise(error, loading, responseCatch));
};

export const patch = (
  url,
  data,
  config = {},
  loading = false,
  responseThen = undefined,
  responseCatch = undefined,
  headers,
) => {
  if (loading) {
    store.dispatch(turnOnLoadingActionCreator());
  }
  axios
    .patch(url, data, getOptions(config, headers))
    .then((response) => resolvePromise(response, loading, responseThen))
    .catch((error) => rejectPromise(error, loading, responseCatch));
};

export const get = (url, config, loading = true, responseThen, responseCatch, headers, withoutBearer = false) => {
  if (loading) {
    store.dispatch(turnOnLoadingActionCreator());
  }
  axios
    .get(url, getOptions(config, headers, withoutBearer))
    .then((response) => resolvePromise(response, loading, responseThen))
    .catch((error) => rejectPromise(error, loading, responseCatch));
};

/*export const getNoBearer = (url, config, loading = true, responseThen, responseCatch, headers) => {
  if (loading) {
    store.dispatch(turnOnLoadingActionCreator());
  }
  axios
    .get(url, {
      headers: {
        "Cache-Control": "no-cache, no-store, must-revalidate",
        Pragma: "no-cache",
        Expires: "0",
      },
    })
    .then((response) => resolvePromise(response, loading, responseThen))
    .catch((error) => rejectPromise(error, loading, responseCatch));
};*/

export const del = (url, config, loading, responseThen = undefined, responseCatch = undefined, headers) => {
  if (loading) {
    store.dispatch(turnOnLoadingActionCreator());
  }
  axios
    .delete(url, getOptions(config, headers))
    .then((response) => resolvePromise(response, loading, responseThen))
    .catch((error) => rejectPromise(error, loading, responseCatch));
};

/*export const fetch = async (documentId) => {
  const keycloak = store.getState().common.keycloak;
  await ge(
    `https://admin-int.tiivii.com/api-tiivii-plenary/v1/public/torrevieja/plenary-sessions/107/files/${documentId}`,
    {
      headers: {
        Authorization: `Bearer ${keycloak?.token}`,
      },
    },
  );
};*/
export const getPromise = (url) => axios.get(url);

export const getCancelToken = () => axios.CancelToken;
export const getCancelRequest = (error) => axios.isCancel(error);
/**
 * Dado un valor para url = 'https://bert.tiivii.com/api/studio/:id/event/:event_id' y un valor para variables = {
 *   id: "un_id", event_id: "un_event_id"}, devolvería https://bert.tiivii.com/api/studio/un_id/event/un_event_id
 * @param url Url con placeholders
 * @param variables objeto con las substituciones a realizar
 * @returns {string} con la url generada a partir de las substituciones.
 */
export const buildUrl = (url, variables) => {
  return url.replace(/:(\w+)/g, (match, p1) => (p1 in variables ? variables[p1] : match));
};
